//Calling the API's at regular at interval of time
export const TIMEINTERVAL = 120000; // 2 minutes in milliseconds (2 minutes * 60,000)

//Polling interval for fetchPermittedCustomer
export const POLLINGINTERVAL = 20000;

//Calling the page size in tableData
export const PAGESIZE = 8;

export const VERYLOWFUEL = 25;
export const LOWFUEL = 50;
export const REFUELED = 90;

export const INTRODUCTION = "Introduction";
export const GENERAL = "General";
export const CHARGERS = "Chargers";
export const POWER_GENERATION = "Power";
export const FUEL = "Fuel";
export const SOLAR = "Solar";
export const STARLINK = "Starlink";
export const DOCS = "Docs";
export const REPORTS = "Reports";
export const CAMERAS = "Cameras";
export const ALARMS = "Alarms";
export const HELP = "Help";
export const MAPS = "Maps";
export const COMMUNICATION = "Communication"

//Calling initial zoom level of the map when it loads.
export const MAP_ZOOM_LEVEL = 16

// Setting timezones in Power Generation Tabs and fuelchart
const TIMEZONES: { value: string, label: string }[] = [
    { value: "UTC", label: "UTC" },
    { value: "America/Chicago", label: "US/Central" },
    { value: "America/New_York", label: "US/Eastern" },
    { value: "America/Denver", label: "US/Mountain" },
    { value: "America/Los_Angeles", label: "US/Pacific" }
];

// Setting headers for downloaded csv
const HEADERS = [
    { label: "Datetime", key: "value_datetime" },
    { label: "Value", key: "value" },
    { label: "Value Name", key: "value_name" },
    { label: "Generator Name", key: "generator_name" },
];

export { TIMEZONES, HEADERS };

export const CHARGER_DATA_POLLING_INTERVAL = 60000; // 30 seconds in milliseconds (30 seconds * 1,000)

export const GREEN = 2

export const RED = 0

export const ORANGE = 1;

export const NEW_CHARGER_POLLING_RATE = 60000;