import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import ChargerSummaryCard from './ChargerSummaryCard';
import './charger.css'
import ChargerList from './ChargerList';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CHARGERS, GENERAL } from '../../utils/constants';
import _ from 'lodash';

const Chargers = () => {
    const { chargerId } = useParams();
    const navigate = useNavigate();
    const { chargers, isLoadingChargerData, errorCharger } = useSelector((state: RootState) => state.chargers);
    const { selectedCustomerName, customerSites,
        selectedSite } = useSelector((state: RootState) => state.userInfo);

    useEffect(() => {
        if (chargers.length > 0 && !isLoadingChargerData && !_.isEmpty(chargerId)) {
            const check = chargers.filter((data) => {
                return data.id === Number(chargerId)
            })
            if (check.length === 0) {
                navigate(`/${selectedCustomerName}/${selectedSite}/${GENERAL}`);
            }
        }
        else if (errorCharger) {
            if (errorCharger !== 'No chargers available' && errorCharger !== 'Request canceled' && errorCharger !== null) {
                //toaster
                navigate(`/${selectedCustomerName}/${selectedSite}/${GENERAL}`);
            }
        }
    }, [chargers, errorCharger])

    const selectedSiteObj = customerSites.find((data) => data.name === selectedSite)
    const present_components: any = selectedSiteObj && selectedSiteObj?.preferences?.present_components;


    useEffect(() => {
        if (customerSites.length > 0) {
            if (present_components?.length > 0) {
                if (!present_components.includes(CHARGERS)) {
                    navigate(`/${selectedCustomerName}/${selectedSite}/${GENERAL}`);
                }
            }
        }
    }, [present_components, customerSites])

    return (
        <div className='charger'>
            {!chargerId &&
                <div>
                    <ChargerSummaryCard />
                    <div style={{ height: '20px' }}></div>
                    <ChargerList />
                </div>}
            <Outlet />
        </div>
    )
}

export default Chargers