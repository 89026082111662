import axios from "axios";
import { useNavigate } from "react-router-dom";
import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { initiateLogin } from "../../utils/common";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { logoutUser } from "../../redux/services/customerService";

const Logout: React.FC = () => {
    const navigate = useNavigate();

    const protocol = process.env.REACT_APP_PROTOCOL || "";
    const url = process.env.REACT_APP_API_URL || "";
    const baseUrl = protocol + url;
    const dispatch = useDispatch<AppDispatch>();

    const signOut = () => {
        dispatch(logoutUser()).then(() => {
            // Redirect to login page or initiate login
            initiateLogin();
        });
    };

    return (
        <div onClick={signOut} style={{ paddingRight: "10px", cursor: "pointer" }}>
            <div>
                <Tooltip color="blue" title="Logout">
                    <LogoutOutlined /> &nbsp; Logout
                </Tooltip>
            </div>
        </div>
    );
};

export default Logout;
