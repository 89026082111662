import { InfoCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Card, Col, Row, Select, Space, Spin, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { Connector, SummaryData } from '../../redux/types/chargerTypes';
import { fetchSummaryData } from '../../redux/services/chargerSevice';

const ChargerSummaryCard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [selectedFilter, setSelectedFilter] = React.useState('Today');
    const [fromDate, setFromDate] = React.useState(dayjs().startOf('day').toISOString());
    const [toDate, setToDate] = React.useState(dayjs().endOf('day').toISOString());
    const { chargers, error, isLoadingChargerData, summary: summaryDataFromResponse, isLoadingSummary: summaryDataLoading } = useSelector((state: RootState) => state.chargers);
    const chargerCount = chargers.length;
    const { permittedCustomers,
        permittedCustomersLoading,
        permittedCustomersError,
        customerSites,
        selectedCustomerName,
        selectedSite,
        customerSitesLoading,
        customerSitesError } = useSelector((state: RootState) => state.userInfo);

    const Options = [
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
    ]

    const handleChange = (value: string) => {
        setSelectedFilter(value);
        switch (value) {
            case 'Today':
                setFromDate(dayjs().startOf('day').toISOString());
                setToDate(dayjs().endOf('day').toISOString());
                break;
            case 'Yesterday':
                setFromDate(dayjs().subtract(1, 'day').startOf('day').toISOString());
                setToDate(dayjs().subtract(1, 'day').endOf('day').toISOString());
                break;
            case 'Last 7 Days':
                setFromDate(dayjs().subtract(7, 'day').startOf('day').toISOString());
                setToDate(dayjs().endOf('day').toISOString());
                break;
            case 'Last 30 Days':
                setFromDate(dayjs().subtract(30, 'day').startOf('day').toISOString());
                setToDate(dayjs().endOf('day').toISOString());
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (fromDate && toDate && selectedCustomerName && selectedSite) {
            dispatch(fetchSummaryData({ selectedCustomerName, avail_sites: customerSites, selectedSite: selectedSite, fromDate, toDate }));
        }
    }, [fromDate, toDate, selectedCustomerName, selectedSite, dispatch]);

    const ChargerCard = ({ title, tooltip, value, isLoading }: { title: string, tooltip: string, value: any, isLoading: boolean }) => {
        return (
            <Card hoverable className="card-custom">
                <Card.Grid hoverable={false} className="card-grid-custom">
                    <Tooltip color="blue" title={tooltip}>
                        <Typography.Text strong className="tooltip-title">
                            {title} <InfoCircleOutlined />
                        </Typography.Text>
                    </Tooltip>
                    <Typography.Title level={1} className="tooltip-value">
                        <Space>
                            {isLoading ? <Spin spinning={isLoading} size='large' /> : <span>{value}</span>}
                        </Space>
                    </Typography.Title>
                </Card.Grid>
            </Card>
        );
    };

    const calculateTotalEnergyConsumed = (data: SummaryData) => {
        let totalEnergyConsumed = 0;
        data && data.forEach(item => {
            item.session.forEach(session => {
                totalEnergyConsumed += session.metrics.energyConsumed || 0;
            });
        });
        return totalEnergyConsumed / 1000;
    };

    const getFaultsCount = (data: SummaryData) => {
        const checkData = data[0]?.connector
        return checkData?.filter(connector => connector.connectors[0].status === "Faulted")?.length || 0;
    }

    const getChargingSessions = (data: SummaryData) => {
        const checkData = data[0]?.session
        return checkData?.filter(session => session.status === "Ended").length;
    }

    const selectDropDown = (
        <div>
            Charger Summary for <Select size='large' style={{ width: 150 }} options={Options} defaultValue={'Today'} placeholder="Select range" onChange={handleChange} />
        </div>
    )
    return (
        <Card title={selectDropDown}>
            <Row justify="space-around" gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={isLoadingChargerData}
                        title="Total Chargers"
                        tooltip="Total Number of Chargers in the Site"
                        value={chargerCount}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={summaryDataLoading}
                        title="KWH consumed"
                        tooltip={`Total KWH consumed ${selectedFilter}`}
                        value={calculateTotalEnergyConsumed(summaryDataFromResponse)}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={summaryDataLoading}
                        title={`Faults`}
                        tooltip={`Total number of faults encountered for ${selectedFilter}`}
                        value={getFaultsCount(summaryDataFromResponse)}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={summaryDataLoading}
                        title="Charging sessions"
                        tooltip={`Number of Charging sessions Info for ${selectedFilter}`}
                        value={getChargingSessions(summaryDataFromResponse)}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export default ChargerSummaryCard