import axios, { AxiosError, CancelTokenSource } from "axios";
import { Charger, ChargerData, ChargerModel, ChargerStatusResponse, FetchChargerListPayload, SessionData, SummaryData } from "../types/chargerTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerSite } from "../types/customerTypes";

const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl: any = protocol + url;

let cancelTokenSource2: CancelTokenSource | null = null;

export const fetchChargerList = createAsyncThunk<
    ChargerData[], // Type of the fulfilled action payload
    FetchChargerListPayload, // Type of the argument passed to the async thunk
    {
        rejectValue: string; // Type of the rejected action payload
    }
>('sites/fetchChargerList', async (payload, thunkAPI) => {
    const { selectedCustomerName, avail_sites, selectedSite } = payload;

    // Cancel the previous request, if any
    if (cancelTokenSource2) {
        cancelTokenSource2.cancel('Request canceled');
    }

    // Create a new cancel token source for the new request
    cancelTokenSource2 = axios.CancelToken.source();

    // Find the site id based on the selected site name
    const site = avail_sites.find((x) => x.name === selectedSite);
    if (!site) {
        return thunkAPI.rejectWithValue('Site not found');
    }

    try {
        // Make the API request
        const { data } = await axios.get<ChargerData[]>(`${baseUrl}charger/list/${site.id}/`, {
            withCredentials: true,
            headers: {
                'customer': selectedCustomerName, // Include the customer attribute in the headers
            },
            cancelToken: cancelTokenSource2.token,
        });

        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

interface Payload {
    selectedCustomerName: string;
    avail_sites: CustomerSite[];
    selectedSite: string;
    fromDate: string;
    toDate: string;
}

let cancelTokenSource: CancelTokenSource | null = null;

export const fetchSummaryData = createAsyncThunk<SummaryData, Payload>(
    'trend/summary',
    async (payload, thunkAPI) => {
        const { selectedCustomerName, avail_sites, selectedSite, fromDate, toDate } = payload;

        // Cancel the previous request, if any
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled');
        }

        // Create a new cancel token source for the new request
        cancelTokenSource = axios.CancelToken.source();

        // Function to find the site ID based on the selected site name
        const findSiteId = (siteName: string) =>
            avail_sites.find((site) => site.name === siteName);

        const siteId = findSiteId(selectedSite);

        if (!siteId) {
            return thunkAPI.rejectWithValue('Site not found');
        }

        try {
            // Make the API request with the cancel token
            const { data } = await axios.get<SummaryData>(
                `${baseUrl}trend/chargersummary/${siteId.id}/?from_date=${fromDate}&to_date=${toDate}`,
                {
                    withCredentials: true,
                    headers: { customer: selectedCustomerName },
                    cancelToken: cancelTokenSource.token,
                }
            );
            return data;
        } catch (error: any) {
            // Check if the request was canceled
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
                return thunkAPI.rejectWithValue({ canceled: true });
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

interface Address {
    city: string;
    state: string;
    country: string;
    postalCode: string;
    streetAndNumber: string;
}

interface Location {
    _id: string;
    active: boolean;
    address: Address | null;
    timezone: string;
    createdAt: string;
    meta_data: {
        notes: string;
    } | null;
    updatedAt: string;
    coordinates: [number, number];
    operatorName: string;
}

interface ChargerStatus {
    id: number;
    name: string;
    location: Location | null;
    connectors: any | null; // Replace 'any' with actual type if known
    endpoint: string;
    event_time: string | null;
    charger: number | null;
}

interface ChargerStatusPayload {
    chargersId: number[];
    selectedCustomerName: string;
}



export const fetchAllChargersCurrentStatus = createAsyncThunk<
    ChargerModel[],
    ChargerStatusPayload,
    { rejectValue: string }
>('charger/fetchAllChargersCurrentStatus', async (payload, thunkAPI) => {
    try {
        const { chargersId, selectedCustomerName } = payload;
        const currentStatus: ChargerModel[] = [];

        for (const chargerId of chargersId) {
            const { data } = await axios.get(`${baseUrl}charger/status/${chargerId}/`, {
                withCredentials: true,
                headers: {
                    customer: selectedCustomerName,
                },
            });
            currentStatus[chargerId] = data;
        }
        return currentStatus;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

interface FetchSessionsPayload {
    fromDate?: string;
    toDate?: string;
    chargerStationId: string;
    selectedCustomerName: string;
}

export const fetchAllSessionDataForChargers = createAsyncThunk<
    SessionData[], // Return type (array of SessionData)
    FetchSessionsPayload, // Argument type
    { rejectValue: string } // Optional reject value
>(
    'sites/fetchAllSessionDataForChargers',
    async (payload: FetchSessionsPayload, thunkAPI) => {
        const { fromDate, toDate, chargerStationId, selectedCustomerName } = payload;

        // If there's already an ongoing request, cancel it
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource = axios.CancelToken.source();

        const fromrange = fromDate ?? '';
        const torange = toDate ?? '';

        try {
            // API call
            const { data } = await axios.get<SessionData[]>(
                `${baseUrl}charger/session/${chargerStationId}/?from_date=${fromrange}&to_date=${torange}`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource.token, // Use the new cancel token
                }
            );

            return data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled'); // Optional: you can return an empty array or specific error message
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

interface FetchChargerStatusPayload {
    chargerId: string;
    selectedCustomerName: string;
    fromDate: string;
    toDate: string;
}

let cancelTokenSource3: CancelTokenSource | null = null;

export const fetchChargerStatusForErrorTab = createAsyncThunk<ChargerStatusResponse, FetchChargerStatusPayload>(
    'charger/fetchChargerStatusForErrorTab',
    async (payload, thunkAPI) => {
        try {
            const { chargerId, selectedCustomerName, fromDate, toDate } = payload;
            cancelTokenSource3 = axios.CancelToken.source();

            const { data } = await axios.get<ChargerStatusResponse>(`${baseUrl}charger/status_summary/${chargerId}/?from_date=${fromDate}&to_date=${toDate}`, {
                withCredentials: true,
                headers: {
                    'customer': selectedCustomerName,
                },
                cancelToken: cancelTokenSource3?.token,
            });

            return data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', (error as AxiosError).message);
                return thunkAPI.rejectWithValue({ canceled: true });
            } else {
                return thunkAPI.rejectWithValue((error as AxiosError).message);
            }
        }
    }
);
