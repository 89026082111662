import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import AppContent from './view/auth/AppContent';
import ErrorPage from './view/auth/ErrorPage';
import Dashboard from './view/dashboard/Dashboard';
import './App.css';
import General from './view/general/General';
import Chargers from './view/charger/Chargers';
import Maps from './view/maps/Maps';
import Power from './view/power/Power';
import Fuel from './view/fuel/Fuel';
import Solar from './view/solar/Solar';
import Reports from './view/reports/Reports';
import Alarms from './view/alarms/Alarms';
import IndividualCharger from './view/charger/IndividualCharger';
import Cameras from './view/cameras/Cameras';
import PowerIndividual from './view/power/PowerIndividual';
import Introduction from './view/introduction/introduction';
import { ALARMS, CAMERAS, CHARGERS, DOCS, FUEL, GENERAL, INTRODUCTION, MAPS, POWER_GENERATION, REPORTS, SOLAR, STARLINK } from './utils/constants';
import FuelIndividual from './view/fuel/FuelIndividual';
import StarLink from './view/starlink/Starlink';
import GeneralPageSingle from './view/general/Generalsingle';
import ErrorLoginPage from './view/error/errorLogin';
import Docs from './view/docs/Docs';
import Camera2 from './view/cameras/Cameras2';

const toLowerCasePath = (path: string) => path.toLowerCase();

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppContent />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ":customer/:site",
        element: <Dashboard />,
        children: [
          {
            index: true, // Default redirect
            element: <Navigate to={toLowerCasePath(GENERAL)} replace />
          },
          { path: toLowerCasePath(GENERAL), element: <GeneralPageSingle /> },
          { path: toLowerCasePath(CHARGERS), element: <Chargers />, children: [{ path: ":chargerId", element: <IndividualCharger /> }] },
          { path: toLowerCasePath(MAPS), element: <Maps /> },
          {
            path: toLowerCasePath(POWER_GENERATION), element: <Power />, children: [
              { path: ":powerId", element: <PowerIndividual /> }
            ]
          },
          {
            path: toLowerCasePath(FUEL), element: <Fuel />, children: [
              { path: ":fuelId", element: <FuelIndividual /> }
            ]
          },
          { path: toLowerCasePath(SOLAR), element: <Solar /> },
          { path: toLowerCasePath(REPORTS), element: <Reports /> },
          { path: toLowerCasePath(CAMERAS), element: <Camera2 /> },
          { path: toLowerCasePath(ALARMS), element: <Alarms /> },
          { path: toLowerCasePath(INTRODUCTION), element: <Introduction /> },
          { path: toLowerCasePath(STARLINK), element: <StarLink /> },
          { path: toLowerCasePath(DOCS), element: <Docs /> },
          { path: 'errorLogin', element: <ErrorLoginPage /> }
        ]
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
