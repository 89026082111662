// src/services/customerService.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { UserInfo, PermittedCustomer, CustomerSite, UpdateShowIntroStatusResponse, UpdateShowIntroStatusPayload, GeneralDetails, FetchGeneralDetailsPayload } from '../types/customerTypes';
import { clearCookie } from '../../utils/common';

const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl = protocol + url;

let isPollingActive = false;
let pollingInterval: NodeJS.Timeout;

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseUrl}oauth/logout/`, {}, { withCredentials: true });
            localStorage.removeItem('user');
            clearCookie();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Logout failed');
        }
    }
);

// Async thunk to fetch user info
export const fetchUserInfo = createAsyncThunk<UserInfo, void, { rejectValue: string }>(
    'userInfo/fetchUserInfo',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<UserInfo>(`${baseUrl}customer/userinfo/`, { withCredentials: true });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async thunk to fetch permitted customers
export const fetchAllPermittedCustomers = createAsyncThunk<PermittedCustomer[], void, { rejectValue: string }>(
    'userInfo/fetchAllPermittedCustomers',
    async (_, thunkAPI) => {
        try {
            const { data } = await axios.get<PermittedCustomer[]>(`${baseUrl}microapi/permitted_customers/`, { withCredentials: true });

            if (isPollingActive) {
                clearInterval(pollingInterval); // Stop polling if it's active
                isPollingActive = false; // Reset polling flag
            }

            return data;
        } catch (error: any) {
            if (error.response === undefined) {
                return thunkAPI.rejectWithValue(error.message);
            }
            if (error.response.status === 401) {
                clearCookie();
            }

            if (error.response.status === 403) {
                if (!isPollingActive) {
                    startPollingFetchAllPermittedCustomers(thunkAPI.dispatch); // Start polling if it's not active
                    isPollingActive = true; // Set polling flag
                }
            }

            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Function to start polling
function startPollingFetchAllPermittedCustomers(dispatch: any) {
    pollingInterval = setInterval(() => {
        dispatch(fetchAllPermittedCustomers());
    }, 5000); // Poll every 5 seconds (adjust as needed)
}

// Async thunk to fetch customer sites
export const fetchAllCustomerSites = createAsyncThunk<CustomerSite[], string, { rejectValue: string }>(
    'sites/fetchAllCustomerSites',
    async (selectedCustomerName, thunkAPI) => {
        try {
            const { data } = await axios.get<CustomerSite[]>(`${baseUrl}crud/get_customersites`, {
                withCredentials: true,
                headers: {
                    'customer': selectedCustomerName, // Include the customer attribute in the headers
                }
            });
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateShowIntroStatus = createAsyncThunk<
    UpdateShowIntroStatusResponse,          // Return type on success
    UpdateShowIntroStatusPayload,           // Argument type
    { rejectValue: string }                // Type for rejectWithValue
>(
    'customer/updateShowIntroStatus',
    async (payload, thunkAPI) => {
        try {
            const response = await axios.patch<UpdateShowIntroStatusResponse>(
                `${baseUrl}customer/userinfo/`,
                payload,
                { withCredentials: true }
            );
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                // Extract a meaningful error message from the response
                return thunkAPI.rejectWithValue(
                    error.response?.data?.message || error.message
                );
            }
            // Fallback error message
            return thunkAPI.rejectWithValue('An unknown error occurred');
        }
    }
);

export const fetchGeneralDetails = createAsyncThunk<
    GeneralDetails, // Return type of the fulfilled action
    FetchGeneralDetailsPayload, // Argument type for the thunk
    { rejectValue: string } // Reject value type in case of error
>('sites/fetchGeneralDetails', async (payload, thunkAPI) => {
    const { selectedCustomerName, avail_sites, selectedSite } = payload;

    const findSiteId = (selectedSite: string) =>
        avail_sites.find((site) => site.name === selectedSite);

    const siteid = findSiteId(selectedSite);

    if (!siteid) {
        return thunkAPI.rejectWithValue('Site not found');
    }

    try {
        const { data } = await axios.get<GeneralDetails>(
            `${baseUrl}microapi/generaldetails/${siteid.id}/`,
            {
                withCredentials: true,
                headers: {
                    'customer': selectedCustomerName, // Include the customer attribute in the headers
                },
            }
        );
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});