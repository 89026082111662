import React from "react";
import {
    Card,
    Collapse,
    Typography,
    Space,
    Radio,
    Button,
    Timeline
} from "antd";
import { PopupForm } from "./ReportPopUp";



const alignOptions = ['flex-start', 'center', 'flex-end'];

const reportDescriptions = [
    {
        id: 0,
        name: 'Charger sessions - Week of 4/21/2024',
    },
    {
        id: 1,
        name: 'Vehicle location - Monthly',
    }
];

// const myitems = reportDescriptions.map((row) => (
//     {
//         id: row.id,
//         label: row.name,
//         children:
//             <>
//                 <Typography.Paragraph editable  className='m-0'>Charger Session Report Options
//                     <br /><br />
//                     <Radio.Group>
//                         <Space direction="vertical">
//                             <Radio value={1}>All Sessions</Radio>
//                             <Radio value={2}>Charger Specific Sessions</Radio>
//                             <Radio value={3}>Very Long Sessions</Radio>
//                             <Radio value={3}>Failed Sessions</Radio>
//                         </Space>
//                     </Radio.Group>
//                 </Typography.Paragraph>
//                 <br />
//                 <Button>Run</Button>&nbsp;&nbsp;&nbsp;&nbsp;
//                 <Button>Schedule</Button>&nbsp;&nbsp;&nbsp;&nbsp;
//                 <Button>Email</Button>&nbsp;&nbsp;&nbsp;&nbsp;
//             </>

//     }
// ))

// const citems = myitems.map((row) => (
//     {
//         id: row.id,
//         label: row.label,
//         children:
//             <>
//                 <Timeline mode="left"
//                     items={[
//                         {
//                             color: 'red',
//                             label: 'Charger sessions - Week of 4/21/2024',
//                             children: 'Low Fuel detected - 20%',
//                         },
//                         {
//                             color: 'yellow',
//                             label: 'Vehicle location - Monthly',
//                             children: 'Email Alert sent to mitchell@pioneer-emobility.com',
//                         },
//                     ]}
//                 />
//             </>
//     }
// ))

export default function Reports() {
    var value1 = false
    const items = [
        {
            key: '1',
            label: 'Charger Sessions',
            children:
                <>
                    <Typography.Paragraph editable className='m-0'>Charger Session Report Options
                        <br /><br />
                        <Radio.Group value={1}>
                            <Space direction="vertical">
                                <Radio value={1}>All Sessions</Radio>
                                <Radio value={2}>Charger Specific Sessions</Radio>
                                <Radio value={3}>Very Long Sessions</Radio>
                                <Radio value={4}>Failed Sessions</Radio>
                            </Space>
                        </Radio.Group>
                    </Typography.Paragraph>
                    <br />
                    <Button>Run</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button>Schedule</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button>Email</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                </>
        },
        {
            key: '2',
            label: 'Vehicle Location',
            children:
                <>
                    <Typography.Paragraph editable className='m-0'>Charger Session Report Options
                        <br /><br />
                        <Radio.Group value={3}>
                            <Space direction="vertical">
                                <Radio value={1}>This Month - Summary</Radio>
                                <Radio value={2}>This Month - Detailed</Radio>
                                <Radio value={3}>By Month - Summary</Radio>
                                <Radio value={4}>By Month - Detailed</Radio>
                            </Space>
                        </Radio.Group>
                    </Typography.Paragraph>
                    <br />
                    <Button>Run</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button>Schedule</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button>Email</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                </>
        },
        {
            key: '3',
            label:
                'Fuel History',
            children:
                <>
                    <Typography.Paragraph editable className='m-0'>Charger Session Report Options
                        <br /><br />
                        <Radio.Group value={1}>
                            <Space direction="vertical">
                                <Radio value={1}>Refuling sessions</Radio>
                                <Radio value={2}>Consumpton by Month</Radio>
                                <Radio value={3}>Consumption by Week</Radio>
                            </Space>
                        </Radio.Group>
                    </Typography.Paragraph>
                    <br />
                    <Button>Run</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button>Schedule</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button>Email</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                </>
        },
    ];
    return (
        <>
            <Card size="small" style={{ minHeight: '117px' }} className="cardUnselected"
                title="Report Options">&nbsp;&nbsp;
                <PopupForm
                    onChange={(item: any) => {
                    }}
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <Button>Run</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                <Button>Schedule</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                <Button>Remove</Button>&nbsp;&nbsp;&nbsp;&nbsp;
            </Card>
            <Collapse accordion items={items} defaultActiveKey={['2']} />
        </>
    )
}

//
// export default function Reportsold() {
//     return (
//         <>
//             <Card size="small" tyle={{minHeight: '117px'}} className="cardUnselected"
//                   title="Report Options">&nbsp;&nbsp;
//                 <PopupForm
//                     onChange={(item) => {
//                     }}
//                 />&nbsp;&nbsp;&nbsp;&nbsp;
//                 <Button>Run</Button>&nbsp;&nbsp;&nbsp;&nbsp;
//                 <Button>Schedule</Button>&nbsp;&nbsp;&nbsp;&nbsp;
//                 <Button>Remove</Button>&nbsp;&nbsp;&nbsp;&nbsp;
//             </Card>
//             <Collapse accordion items={myitems} defaultActiveKey={['0']}/>
//         </>
//     )
// }
//
// // https://codesandbox.io/p/sandbox/ant-design-form-in-modal-example-mcnxx?file=%2Fsrc%2Findex.js
//
