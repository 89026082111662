import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export default function Solar() {
    const customerSites = useSelector((state: RootState) => state.userInfo.customerSites);

    const selectedSite = useSelector((state: RootState) => state.userInfo.selectedSite);

    const victronSite = customerSites.find(site => site.name === selectedSite)?.victron_link;

    return (
        <React.Fragment>
            <Card title="SOLAR" className="w-full" bodyStyle={{ height: '100%' }}>
                <iframe
                    title="victron-iframe"
                    style={{ width: "100%", height: "500px", border: "none", backgroundColor: "none" }}
                    src={victronSite}
                ></iframe>
            </Card>
        </React.Fragment>
    );
}
