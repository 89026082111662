import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllChargersCurrentStatus, fetchChargerList, fetchSummaryData, fetchAllSessionDataForChargers, fetchChargerStatusForErrorTab } from '../services/chargerSevice';
import { Charger, SummaryData, SessionData, Connector2, ChargerStatusResponse } from '../types/chargerTypes';
import _ from 'lodash';

// Define the ChargerModel and ChargerData types
interface ChargerModel {
    id: number;
    vendor: string;
    model: string;
    capacity: number;
    charger_type: string;
    connectors: Connector2;
    image_src: string;
    charger_link: string;
}

interface ChargerData {
    id: number;
    charger_title: string;
    charger_model: ChargerModel | any;
    serial_number: string;
    station_id: string;
    station_name: string;
    api_source: string;
    extra_data: Record<string, unknown>;
}

// Define the ChargerState type
interface ChargerState {
    chargers: ChargerData[];
    dataTobeDis: any,
    sessionTabIndex: string;
    chargersId: number[];
    summary: SummaryData;
    chargerStatus: ChargerStatusResponse[];
    sessionData: SessionData[]; // New state for session data
    isLoadingChargerData: boolean;
    fetchingChargerDataForErrorTab: boolean;
    errorTabChargers: number[];

    isLoadingSummary: boolean;
    isLoadingChargersStatus: boolean;
    isLoadingSessionData: boolean; // New loading state for session data
    error: string | null;
    errorCharger: string | null;
}

// Initialize the state
const initialState: ChargerState = {
    chargers: [],
    errorCharger: '',
    dataTobeDis: {},
    sessionTabIndex: '1',
    chargersId: [],
    errorTabChargers: [],
    summary: [],
    chargerStatus: [],
    sessionData: [], // Initialize session data state
    isLoadingChargerData: false,
    isLoadingSummary: false,
    fetchingChargerDataForErrorTab: false,
    isLoadingChargersStatus: false,
    isLoadingSessionData: false, // Initialize loading state
    error: null,
};

// Create the slice
const chargerSlice = createSlice({
    name: 'charger',
    initialState,
    reducers: {
        setSessionTabIndex(state, action) {
            state.sessionTabIndex = action.payload
        },
        clearChargerData(state) {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChargerList.pending, (state) => {
                state.isLoadingChargerData = true;
                state.errorCharger = null;
            })
            .addCase(fetchChargerList.fulfilled, (state, action: PayloadAction<ChargerData[]>) => {
                state.isLoadingChargerData = false;
                state.chargers = action.payload;
                state.errorCharger = action.payload.length === 0 ? 'No chargers available' : ''
                state.chargersId = _.compact(_.map(action.payload, "id"));
            })
            .addCase(fetchChargerList.rejected, (state, action) => {
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.isLoadingSessionData = true;
                }
                else state.isLoadingSessionData = false;
                state.errorCharger = action.error.message || 'Failed to fetch charger list';
            })
            .addCase(fetchSummaryData.pending, (state) => {
                state.isLoadingSummary = true;
                state.error = null;
            })
            .addCase(fetchSummaryData.fulfilled, (state, action: PayloadAction<SummaryData>) => {
                state.isLoadingSummary = false;
                state.summary = action.payload;
            })
            .addCase(fetchSummaryData.rejected, (state, action: any) => {
                if (action.payload.canceled) {
                    state.isLoadingSummary = true; // Correct handling of aborted action
                } else {
                    state.isLoadingSummary = false;
                    state.error = action.error.message || 'Failed to fetch summary data';
                }
            })
            .addCase(fetchAllChargersCurrentStatus.pending, (state) => {
                state.isLoadingChargersStatus = true;
                state.error = null;
            })
            .addCase(fetchAllChargersCurrentStatus.fulfilled, (state, action: PayloadAction<any>) => {
                state.chargerStatus = action.payload;
                state.isLoadingChargersStatus = false;
            })
            .addCase(fetchAllChargersCurrentStatus.rejected, (state, action) => {
                state.isLoadingChargersStatus = false;
                state.error = action.error.message || 'Failed to fetch charger status';
            })
            // Handle fetchAllSessionDataForChargers actions
            .addCase(fetchAllSessionDataForChargers.pending, (state) => {
                state.isLoadingSessionData = true; // Loading starts
                state.error = null; // Reset error
            })
            // Fulfilled: Set loading to false and store the fetched data
            .addCase(fetchAllSessionDataForChargers.fulfilled, (state, action: PayloadAction<SessionData[]>) => {
                state.isLoadingSessionData = false; // Loading ends
                state.sessionData = action.payload; // Store session data
            })
            // Rejected: Set loading to false and store the error message
            .addCase(fetchAllSessionDataForChargers.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.error = action.payload || 'Failed to fetch session data'; // Store the error
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.isLoadingSessionData = true;
                }
                else state.isLoadingSessionData = false;
            }).addCase(fetchChargerStatusForErrorTab.pending, (state) => {
                state.fetchingChargerDataForErrorTab = true;
            }).addCase(fetchChargerStatusForErrorTab.rejected, (state, action) => {
                state.fetchingChargerDataForErrorTab = false;
                state.dataTobeDis = initialState.dataTobeDis;
                state.error = action.error.message || 'Failed to fetch charger status';
            }).addCase(fetchChargerStatusForErrorTab.fulfilled, (state, payload) => {
                state.dataTobeDis = payload.payload
                state.fetchingChargerDataForErrorTab = false;
            })
    },
});

export const { clearChargerData, setSessionTabIndex } = chargerSlice.actions;

export default chargerSlice.reducer;
