import { Card, List } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OnStatusImage from "../../assets/transparent-green-circle.png";
import RedCircle from '../../assets/red-circle.png';
import OrangeCircle from '../../assets/orange-circle.png';
import DISABLED_STATUS_IMAGE from "../../assets/transparent-grey-circle.png";
import { CHARGERS, FUEL, GREEN, ORANGE, POWER_GENERATION, RED, SOLAR } from '../../../src/utils/constants';
import { RootState } from '../../rootreducer';
import { AppDispatch } from '../../store';
import { setBpValue, setFuelPercentage } from '../../redux/slices/powerSlice';
import { useNavigate } from 'react-router-dom';

interface SystemStatusProps {
    key: string;
    value: [string, string, string];
}

export default function SystemStatus() {
    const dispatch = useDispatch<AppDispatch>();

    const liveDataArray = useSelector((state: RootState) => state.power.liveDataArray);
    const fuelPercentage = useSelector((state: RootState) => state.power.fuelPercentage);
    const bpValueArray = useSelector((state: RootState) => state.power.bpValueArray);
    const selectedCustomerName = useSelector((state: RootState) => state.userInfo.selectedCustomerName);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setFuelPercentage(
            liveDataArray?.find(item => item.value_name === "Fuel Level" || item.value_name === "Fuel Level (%)")?.value
        ));
    }, [liveDataArray, dispatch]);

    const ampsAValue = liveDataArray?.find(obj => obj.value_name === "Amps A")?.value;
    const ampsAStatus = (ampsAValue === -99 || ampsAValue === 0 || ampsAValue === undefined) ? "OFF" : "ON";

    const { customerSites, selectedSite, generalDetails, generalDetailsLoading } = useSelector((state: RootState) => state.userInfo);
    const { generatorList, generatorListError, loading: isLoadingGeneratorList, selectedGeneratorId, isLoadingBPLiveData } = useSelector((state: RootState) => state.power);


    //   const siteData = useSelector(selectAllSiteDatas);
    //   const selectedSite = siteData.selectedSite;
    //   const avail_sites = siteData.avail_sites;
    const generalItems = customerSites.find(site => site.name === selectedSite)?.preferences?.general;
    const systemStatus = generalItems?.systemstatus;



    const itemsToShow = systemStatus ? Object.entries(systemStatus).filter(([key, value]) => value[1] === "Show") : [];

    const handleItemClick = (item: string) => {
        if (item === 'Generator' || item === 'GeneratorBattery') {
            handlePowerGenerationClick(POWER_GENERATION);
        } else if (item === 'Charger') {
            handleChargerClick(CHARGERS);
        } else if (item === 'SolarBattery') {
            handleSolarClick(SOLAR);
        } else {
            handleFuelClick(FUEL);
        }
    };

    const handlePowerGenerationClick = (item: string) => {
        // dispatch(setFromDate());
        // dispatch(setToDate());
        // dispatch(setBpValue(bpValueArray[0]));
        navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${selectedGeneratorId}`);
    };

    const handleChargerClick = (item: string) => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${CHARGERS}`);
    };

    const handleSolarClick = (item: string) => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${SOLAR}`);
    };

    const handleFuelClick = (item: string) => {
        dispatch(setBpValue("Fuel Level"));
        navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}`);
    };

    return (
        <Card title="System Status" size="small" hoverable style={{ height: "100%", width: "100%", border: "1px solid rgba(0,0,0,.15)" }}>
            <List
                size="small"
                dataSource={itemsToShow}
                renderItem={([key, value]: [string, [string, string, string]]) => (
                    <List.Item style={{ marginBottom: '10px' }} onClick={value[2] === 'API' ? () => handleItemClick(key) : undefined}>
                        <div>
                            <img
                                src={value[2] === 'Static' ? DISABLED_STATUS_IMAGE : ((fuelPercentage === undefined || fuelPercentage === null) && key === 'Fuel') ? DISABLED_STATUS_IMAGE :
                                    key === 'Fuel' && (fuelPercentage !== undefined && fuelPercentage !== null) ? (fuelPercentage >= 50 ? OnStatusImage :
                                        (fuelPercentage < 50 && fuelPercentage >= 25) ? OrangeCircle :
                                            (fuelPercentage < 25 && fuelPercentage >= 0) ? RedCircle :
                                                fuelPercentage < 0 ? DISABLED_STATUS_IMAGE : OnStatusImage) : OnStatusImage
                                }
                                height="25px"
                                alt={key}
                            />
                        </div>
                        <div style={{ textAlign: 'left' }}>{value[0]}</div>
                    </List.Item>
                )}
                locale={{ emptyText: "No Status Configured" }}
            />
        </Card>
    );
}
