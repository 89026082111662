import { Card, Col, Empty, Row, Spin, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Camera2 = () => {
    const [data, setData] = useState(null);
    const [isRendered, setIsRendered] = useState(false); // State to control rendering
    const [loading, setLoading] = useState(false);

    const avail_sites = useSelector((state: RootState) => state.userInfo.customerSites);
    const selectedSite = useSelector((state: RootState) => state.userInfo.selectedSite);
    const cameraLink = avail_sites.find((site) => site.name === selectedSite)?.preferences?.camera_link;

    const CLIENT_ID = '643003e1ce3e9ce5916fce5a02e59f01.access';       // Replace with actual client ID
    const CLIENT_SECRET = '35ee6dd54f8b631e5bb8f1da062784f15b88b316ee8db1359fee61b18cacda80'; // Replace with actual client secret
    const url: any = process.env.REACT_APP_URL;

    useEffect(() => {
        if (cameraLink) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(cameraLink, {
                        headers: {
                            'CF-Access-Client-Id': CLIENT_ID,
                            'CF-Access-Client-Secret': CLIENT_SECRET,
                            'Access-Control-Allow-Origin': url
                        },
                        withCredentials: true,
                    });
                    setData(response?.data);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                }
            };

            // Fetch data immediately
            fetchData();

            // Delay rendering by 2 seconds
            const timer = setTimeout(() => {
                setIsRendered(true); // Enable rendering after 2 seconds
            }, 2000);

            // Clean up the timer on component unmount
            return () => clearTimeout(timer);
        }
    }, [cameraLink]);

    return (
        <React.Fragment>
            <Card title="Camera" loading={loading}>
                {<Row justify="start" className='pb-10'>
                    <Col span={24}>
                        {isRendered && ( // Render only after 2 seconds
                            <Row justify="start" className="rowGraph" style={{ height: '79vh' }}>
                                <div className='w-full h-full'>
                                    <iframe
                                        title="camera-iframe"
                                        allowFullScreen={true}
                                        style={{ width: "100%", height: "100%", border: "none", backgroundColor: "none" }}
                                        src={cameraLink}
                                    ></iframe>
                                </div>
                            </Row>
                        )}
                    </Col>
                </Row>}
                {!loading && !cameraLink && <Empty description="No camera link attached for the site." />}
            </Card>
        </React.Fragment>
    );
}

export default Camera2