import { InfoCircleOutlined, ThunderboltOutlined } from '@ant-design/icons'
import { Card, Col, Row, Select, Space, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { GENERAL, POWER_GENERATION } from '../../utils/constants';
import { clearGenChangeData, clearGraphData, clearPowerState, setSelectedGeneratorId, setSelectedSourceId } from '../../redux/slices/powerSlice';
import { fetchBPLiveData, fetchBPValues } from '../../redux/services/powerService';

const Power = () => {
    const { powerId }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { customerSites: siteData, selectedCustomerName, customerSites, selectedSite } = useSelector((state: RootState) => state.userInfo);
    const preferences = siteData.find((site) => site.name === selectedSite)?.preferences;
    const shouldRenderKWHGenerated = preferences?.power?.powercards?.includes("kWhGenerated");
    const shouldRenderKWHConsumed = preferences?.power?.powercards?.includes("kWhConsumed");
    const shouldRenderEngineHours = preferences?.power?.powercards?.includes("EngineHours");
    const shouldRenderNextMaintenance = preferences?.power?.powercards?.includes("NextMaintenance");
    const { generatorList: generatorListfromAPI, generatorListError, loading: isLoadingGeneratorList, selectedGeneratorId, selectedSourceId, emptyGenId } = useSelector((state: RootState) => state.power);
    const [generatorList, setGeneratorList] = useState<any[]>([]);
    const selectedSiteObj = customerSites.find((data) => data.name === selectedSite)
    const present_components: any = selectedSiteObj && selectedSiteObj?.preferences?.present_components;

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedGeneratorId !== null && !powerId) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${selectedGeneratorId}`);
        }
    }, [selectedGeneratorId])

    useEffect(() => {
        if (emptyGenId) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}`);
        }
    }, [emptyGenId])

    useEffect(() => {
        if (powerId && generatorList.length > 0 && !isLoadingGeneratorList) {
            if (generatorList.filter(item => item.value === Number(powerId)).length > 0) {
                dispatch(setSelectedGeneratorId(Number(powerId)));
                dispatch(setSelectedSourceId(generatorList.find(item => item.value === Number(powerId))?.source_id) || '');
            }
            else {
                dispatch(setSelectedGeneratorId(generatorList[0].value));
                dispatch(setSelectedSourceId(generatorList[0].source_id) || '');
                navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${generatorList[0].value}`);
            }
        }
    }, [powerId, generatorList])

    const ChargerCard = ({ title, icon, tooltip, value, percent }: any) => {
        return (
            <Card hoverable bordered={true} style={{ border: '1px solid lightgray' }}>
                <Card.Grid hoverable className='w-full h-full'>
                    <Tooltip color="blue" title={tooltip}>
                        <Typography.Text strong className='text-base'>
                            {title} <InfoCircleOutlined />
                        </Typography.Text>
                    </Tooltip>
                    <Typography.Title level={1} className='text-34'>
                        <Space>
                            {icon && <ThunderboltOutlined />}
                            <span className='font-bold'>{value}</span>
                        </Space>
                    </Typography.Title>
                </Card.Grid>
            </Card>
        );
    };

    useEffect(() => {
        if (generatorListfromAPI) {
            setGeneratorList(generatorListfromAPI
                .filter(info => info.equipment_name !== null && !['EV Charger', 'EV_Charger'].includes(info.equipment_name))
                .map(info => ({
                    value: info.equipment_id,
                    label: info.equipment_name,
                    source_id: info.source_id
                })))
        }
    }, [generatorListfromAPI])

    const handleEquipmentChange = (e: any) => {
        dispatch(clearGenChangeData());
        dispatch(clearGraphData())
        e && dispatch(setSelectedGeneratorId(e));
        e && dispatch(setSelectedSourceId(generatorList.find(item => item.value === e)?.source_id) || '');
        e && navigate(`${e}`);
    };

    useEffect(() => {
        if (selectedGeneratorId === Number(powerId) && selectedSourceId && generatorList.find(item => item.value === selectedGeneratorId)) {
            // dispatch(fetchBPLiveData({ selectedCustomerName: selectedCustomerName, avail_sites: customerSites, selectedGeneratorId: selectedGeneratorId, selectedSite: selectedSite }));
            dispatch(fetchBPValues({ selectedCustomerName: selectedCustomerName, avail_sites: customerSites, selectedGeneratorId: selectedGeneratorId, selectedSite: selectedSite, sourceId: selectedSourceId }));
        }
    }, [selectedGeneratorId, powerId, selectedSourceId, generatorList])

    return (
        <Card title="Power">
            <Row justify="space-around" gutter={[16, 16]}>
                <Col span={24}>
                    <Row justify="space-around" gutter={[16, 16]}>
                        {[
                            shouldRenderKWHGenerated && {
                                title: "KWH Generated",
                                tooltip: "KWH Generated info",
                                value: 25,
                                percent: 20,
                                icon: <ThunderboltOutlined />
                            },
                            shouldRenderKWHConsumed && {
                                title: "KWH Consumed",
                                tooltip: "KWH Consumed info",
                                value: 20,
                                percent: 20,
                            },
                            shouldRenderEngineHours && {
                                title: "Engine Hours/ Month",
                                tooltip: "Engine Hours this month info",
                                value: 100,
                                percent: 100,
                            },
                            shouldRenderNextMaintenance && {
                                title: "Next Maintenance/Days",
                                tooltip: "Next Maintenance/Days info",
                                value: 10,
                                percent: 10,
                            },
                        ]
                            .filter(Boolean)
                            .map((cardData: any, index: any, array: any) => (
                                <Col
                                    key={index}
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={array.length === 0 ? 24 : 24 / array.length}  // Dynamically adjust based on the number of columns
                                    xl={array.length === 0 ? 24 : 24 / array.length}  // Ensures equal width for all columns
                                >
                                    <ChargerCard
                                        title={cardData.title}
                                        tooltip={cardData.tooltip}
                                        value={cardData.value}
                                        percent={cardData.percent}
                                        icon={cardData.icon}
                                    />
                                </Col>
                            ))}
                    </Row>
                </Col>

            </Row>
            <div style={{ height: 20 }}></div>
            <Row>
                <Col>
                    Select a Generator:  {((generatorList && generatorList[0]) || isLoadingGeneratorList) && <Select
                        value={selectedGeneratorId}
                        style={{ width: 120 }}
                        // allowClear={true}
                        options={generatorList}
                        placeholder="Select a Generator"
                        onClear={() => {
                            dispatch(clearGenChangeData());
                            dispatch(clearGraphData())
                            dispatch(setSelectedGeneratorId(null));
                            dispatch(setSelectedSourceId(''));
                            navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}`);
                        }}
                        loading={isLoadingGeneratorList}
                        onChange={handleEquipmentChange}
                    />}
                </Col>
            </Row>
            <div style={{ height: 20 }}></div>
            <Outlet />
        </Card>
    )
}

export default Power