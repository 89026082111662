import React, { useEffect, useState } from 'react';
import { Result, Button } from 'antd';
import Logo from '../../assets/logo/logo.png';
import { clearCookie, initiateLogin } from '../../utils/common';

const ErrorLoginPage = () => {
    const [counter, setCounter] = useState(15);

    useEffect(() => {
        const timer = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        const redirectTimer = setTimeout(() => {
            initiateLogin();
        }, 15000);

        // Clean up intervals and timeouts on component unmount
        return () => {
            clearInterval(timer);
            clearTimeout(redirectTimer);
        };
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <img width={300} height={100} src={Logo} alt="logo" style={{ marginBottom: 20 }} />
            <Result
                status="404"
                title="A user with the same email ID already exists in the system. Please contact e-Boost administrators."
                subTitle={`Redirecting to the login page in ${counter} seconds...`}
                extra={
                    <Button type="primary" onClick={initiateLogin}>
                        Go to Login
                    </Button>
                }
            />
        </div>
    );
};

export default ErrorLoginPage;
