import { ArrowLeftOutlined, BellOutlined, EnvironmentOutlined, InfoCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, ReloadOutlined, SolutionOutlined, UserOutlined, WifiOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Button, Col, Drawer, Dropdown, Layout, Menu, Row, Select, Skeleton, Spin, theme, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import LogoSmall from "../../assets/logo/logoSmall.png";
import Logo from "../../assets/logo/logo.png";
import UserLogo from '../../assets/Icons/user.226x256.png';
import LocationLogo from '../../assets/Icons/location-pin.190x256.png';
import GeneralLogo from '../../assets/Icons/book.221x256.png';
import ChargerLogo from '../../assets/Icons/electric-charge.256x256.png';
import FuelLogo from '../../assets/Icons/gas-station.256x237.png';
import HelpLogo from '../../assets/Icons/help-circle.256x256.png';
import IntroLogo from '../../assets/Icons/info-empty.256x256.png';
import nuclearPowergenerationLogo from '../../assets/Icons/nuclear-power-plant.220x256.png';
import ReportsLogo from '../../assets/Icons/report.201x256.png';
import SolarLogo from '../../assets/Icons/solar-panel-sun-thin.234x256.png';
import VideoLogo from '../../assets/Icons/video-camera.256x159.png';
import AlarmLogo from '../../assets/Icons/alarm.246x256.png';
import Sider from 'antd/es/layout/Sider';
import './Dashboard.css';
import { Content, Header } from 'antd/es/layout/layout';
import { matchPath, Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootreducer';
import { AppDispatch } from '../../store';
import { clearGeneralDetails, clearUserInfo, setSelectedCustomerName, setSelectedSite } from '../../redux/slices/customerSlice';
import { JSX } from 'react/jsx-runtime';
import { ALARMS, CAMERAS, CHARGERS, COMMUNICATION, DOCS, FUEL, GENERAL, HELP, INTRODUCTION, MAPS, POWER_GENERATION, REPORTS, SOLAR, STARLINK } from '../../utils/constants';
import { fetchAllChargersCurrentStatus, fetchChargerList } from '../../redux/services/chargerSevice';
import { fetchAllCustomerSites, logoutUser } from '../../redux/services/customerService';
import axios from 'axios';
import { initiateLogin } from '../../utils/common';
import { fetchBPLiveData, fetchGeneratorList } from '../../redux/services/powerService';
import { clearPowerState, setSelectedFuelId, setSelectedFuelSourceId, setSelectedGeneratorId, setSelectedSourceId } from '../../redux/slices/powerSlice';
import { clearChargerData } from '../../redux/slices/chargerSlice';
import NoSitesFound from '../noSitesFound/NoSitesFound';


const Dashboard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [noSiteFound, setNoSiteFound] = useState<boolean>(false);
    const chargerData: any = []
    const { permittedCustomers,
        permittedCustomersLoading,
        permittedCustomersError,
        customerSites,
        selectedCustomerName,
        selectedSite,
        customerSitesLoading,
        customerSitesError, userInfo } = useSelector((state: RootState) => state.userInfo);


    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { chargers, error, isLoadingChargerData, chargersId } = useSelector((state: RootState) => state.chargers);

    const { generatorList, selectedFuelId, fuelGenList, loading, selectedGeneratorId } = useSelector((state: RootState) => state.power);

    const presentComponents = customerSites.find((site) => site.name === selectedSite)?.preferences?.present_components;

    useEffect(() => {
        const pollApis = () => {
            // Conditionally dispatch based on presence in presentComponents
            if (presentComponents?.includes(CHARGERS)) {
                dispatch(fetchChargerList({ selectedCustomerName, avail_sites: customerSites, selectedSite }));
            }

            if (presentComponents?.includes(POWER_GENERATION) || presentComponents?.includes(FUEL)) {
                dispatch(fetchGeneratorList({ selectedCustomerName, avail_sites: customerSites, selectedSite }));
            }
        };

        // Poll every 2 minutes (120,000 milliseconds)
        pollApis(); // Call immediately on mount
        const interval = setInterval(pollApis, 120000);

        // Cleanup the interval when component unmounts
        return () => clearInterval(interval);
    }, [selectedSite]);

    useEffect(() => {
        const logUrlOnBack = () => {
            console.log('Back button pressed, current URL:', window.location.href);
        };

        // Add event listener for the popstate event
        window.addEventListener("popstate", logUrlOnBack);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("popstate", logUrlOnBack);
        };
    }, []);

    useEffect(() => {
        if (selectedSite && selectedCustomerName) {
            document.title = (permittedCustomers && permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name ?
                permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name : selectedCustomerName) + ' - ' + selectedSite + ' - ' + 'e-Boost' || 'e-BoostRealM';
        }
    }, [selectedSite, selectedCustomerName]);


    const handleSiderClose = () => {
        setCollapsed(!collapsed);
    };

    const protocol: any = process.env.REACT_APP_PROTOCOL;
    const url: any = process.env.REACT_APP_API_URL;
    const baseUrl = protocol + url;

    const logo = collapsed ? LogoSmall : Logo;

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const isDisabled = permittedCustomers.length === 1 || permittedCustomersLoading || customerSitesLoading;

    const location = useLocation();

    const { customer, site, chargerId: chargerIdFromURL, powerId: powerIdFromURL, fuelId }: any = useParams();

    useEffect(() => {
        if (site !== selectedSite) {
            const siteExists = customerSites.find((data) => data.name === site);
            if (siteExists)
                dispatch(setSelectedSite(siteExists.name))
        }
    }, [site])

    // Extract the current last segment of the path
    const currentPathSegments = location.pathname.split('/');
    let lastSegment: any = currentPathSegments[currentPathSegments.length - 1];

    if (chargerIdFromURL) {
        lastSegment = CHARGERS
    }

    if (powerIdFromURL) {
        lastSegment = POWER_GENERATION
    }

    if (fuelId) {
        lastSegment = FUEL
    }

    const handleSelectCustomerChange = (value: string) => {
        setNoSiteFound(false);
        dispatch(setSelectedCustomerName(value))
        dispatch(clearUserInfo());
        dispatch(clearPowerState());
        dispatch(clearChargerData());
        dispatch(fetchAllCustomerSites(value)).then((response: any) => {
            if (response?.payload.length > 0) {
                dispatch(setSelectedSite(response?.payload[0].name));
                {
                    lastSegment = GENERAL;
                    navigate(`/${value}/${response?.payload[0].name}/${lastSegment}`);
                }
            }
            else {
                dispatch(setSelectedSite('noSitesAttached'));
                navigate(`/${value}/noSitesAttached/${GENERAL}`);
                setNoSiteFound(true);
            }
        }
        );
    }

    const handleSelectSiteChange = (value: string) => {
        dispatch(clearChargerData());
        dispatch(clearPowerState());
        dispatch(setSelectedSite(value));
        const selectedSiteObj = customerSites.find((data) => data.name === value)
        if (selectedSiteObj) {
            const present_components = selectedSiteObj?.preferences?.present_components;
            if (present_components.includes(lastSegment) && ![POWER_GENERATION, FUEL].includes(lastSegment)) {
                navigate(`/${selectedCustomerName}/${value}/${lastSegment}`);
            }
            else if (present_components.includes(lastSegment) && [POWER_GENERATION, FUEL].includes(lastSegment)) {
                // if (selectedCustomerName === 'bchydro')
                //     navigate(`/${selectedCustomerName}/${value}/${GENERAL}`)
            }
            else {
                navigate(`/${selectedCustomerName}/${value}/${GENERAL}`);
            }
        }
    }

    const isGeneralRoute = matchPath({ path: `/:customer/:site/${GENERAL}`, end: true }, location.pathname);
    const isPowerRoute = matchPath({ path: `/:customer/:site/${POWER_GENERATION}`, end: true }, location.pathname);
    const isSpecificPowerRoute = matchPath({ path: `/:customer/:site/${POWER_GENERATION}/:powerId`, end: true }, location.pathname);

    useEffect(() => {
        if (generatorList && loading === false && generatorList.find(generator => generator.equipment_id === selectedGeneratorId)) {
            if (selectedGeneratorId && (isGeneralRoute || isPowerRoute || (isSpecificPowerRoute && selectedGeneratorId === Number(powerIdFromURL)))) {
                // Call the action immediately on first load
                dispatch(fetchBPLiveData({
                    selectedCustomerName,
                    avail_sites: customerSites,
                    selectedGeneratorId,
                    selectedSite,
                }));
            }
        }
    }, [generatorList, selectedGeneratorId, powerIdFromURL])

    useEffect(() => {
        const isGeneralRoute = matchPath({ path: `/:customer/:site/${GENERAL}`, end: true }, location.pathname);
        const isChargersRoute = matchPath({ path: `/:customer/:site/${CHARGERS}`, end: true }, location.pathname);
        const isSpecificChargerRoute = matchPath({ path: `/:customer/:site/${CHARGERS}/:chargerId`, end: true }, location.pathname);

        if (chargersId && (isGeneralRoute || isChargersRoute || isSpecificChargerRoute)) {
            // Call the action immediately for the first time
            dispatch(fetchAllChargersCurrentStatus({ chargersId, selectedCustomerName }));
        }
    }, [chargersId])

    const getSelectedKey = (path: string) => {
        if (path.includes(POWER_GENERATION)) {
            return POWER_GENERATION; // Set POWER_GENERATION as selected
        } else if (path.includes(FUEL)) {
            return FUEL; // Set FUEL as selected
        } else if (path.includes(CHARGERS)) {
            return CHARGERS; // Set FUEL as selected
        } else if (path.includes(MAPS)) {
            return MAPS; // Set MAPS as selected
        } else if (path.includes(REPORTS)) {
            return REPORTS; // Set REPORTS as selected
        } else if (path.includes(CAMERAS)) {
            return CAMERAS; // Set CAMERAS as selected
        } else if (path.includes(ALARMS)) {
            return ALARMS; // Set ALARMS as selected
        } else if (path.includes(SOLAR)) {
            return SOLAR; // Set SOLAR as selected
        } else if (path.includes(INTRODUCTION)) {
            return INTRODUCTION; // Set INTRODUCTION as selected
        } else if (path.includes(GENERAL)) {
            return GENERAL; // Set GENERAL as selected
        } else if (path.includes(STARLINK)) {
            return STARLINK;
        } else if (path.includes(DOCS)) {
            return DOCS;
        }
        return ''; // Return an empty string if no match
    };

    // Determine which menu item should be selected based on the current route
    const selectedKey = getSelectedKey(location.pathname);


    const getItem = (label: string, key: string, icon: JSX.Element | undefined, children: null, onClick: any, disabled?: boolean, onTitleClick?: any) => {
        return {
            label,
            key,
            icon,
            children,
            onClick,
            disabled,
            onTitleClick
        };
    };

    const navigate = useNavigate();

    const initialItems = [
        getItem('Introduction', 'Introduction', <InfoCircleOutlined className='h-18' />, null, () => navigate(INTRODUCTION)),
        getItem('General', 'General', <img src={GeneralLogo} alt="general" className='h-18' />, null, () => navigate(GENERAL)),
    ];

    const [menuItems, setMenuItems] = useState(initialItems);

    useEffect(() => {
        console.log("userInfo updated:", userInfo); // Check if userInfo is correctly updated

        // Start with the base items array
        let updatedItems = [...initialItems];

        // Conditionally remove "Introduction" based on userInfo.show_intro
        if (userInfo && userInfo.show_intro === false) {
            updatedItems = updatedItems.filter(item => item.key !== 'Introduction');
        }

        // Add items based on presentComponents
        presentComponents?.forEach((item) => {
            if (item === CHARGERS) {
                updatedItems.push({
                    label: 'Chargers',
                    key: 'Chargers',
                    icon: <img src={ChargerLogo} alt="charger" className='h-18' />,
                    children: chargerMenuItems,
                    onClick: handleChargerMenuItemClick,
                    disabled: false,
                    onTitleClick: () => handleChargerClick(item),
                });
            } else if (item === POWER_GENERATION) {
                updatedItems.push({
                    label: POWER_GENERATION,
                    key: POWER_GENERATION,
                    icon: <img src={nuclearPowergenerationLogo} alt="power" className='h-18' />,
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: loading,
                    onTitleClick: () => handleMenuItemClick(item),
                });
            } else if (item === FUEL) {
                updatedItems.push({
                    label: FUEL,
                    key: FUEL,
                    icon: <img src={FuelLogo} alt="fuel" className='h-18' />,
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: loading,
                    onTitleClick: () => handleMenuItemClick(item),
                });
            } else {
                updatedItems.push({
                    label: item,
                    key: item,
                    icon: getIconForPresentItem(item),
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: undefined,
                    onTitleClick: undefined,
                });
            }
        });

        // Update the menuItems state
        setMenuItems(updatedItems);

    }, [userInfo, presentComponents, loading, chargers]); // userInfo as dependency

    console.log("menuItems", menuItems);


    const handleChargerClick = (item: any) => {
        navigate(item);
    }

    let chargerMenuItems: any = [];

    chargerMenuItems = chargers.map((data: any) => getItem(data.charger_title, data.id, undefined, null, null, isLoadingChargerData)); // Extract names from chargerData   

    const handleChargerMenuItemClick = (e: any) => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${CHARGERS}/${e.key}`);
    };

    const getIconForPresentItem = (item: any) => {
        if (item === POWER_GENERATION) {
            return <img src={nuclearPowergenerationLogo} alt="power" className='h-18' />;
        } else if (item === FUEL) {
            return <img src={FuelLogo} alt="fuel" className='h-18' />;
        } else if (item === SOLAR) {
            return <img src={SolarLogo} alt="solar" className='h-18' />;
        } else if (item === STARLINK) {
            return <WifiOutlined className='h-18' />;
        } else if (item === REPORTS) {
            return <img src={ReportsLogo} alt="report" className='h-18' />;
        } else if (item === CAMERAS) {
            return <img src={VideoLogo} alt="camera" className='h-13' />;
        } else if (item === ALARMS) {
            return <img src={AlarmLogo} alt="alarm" className='h-18' />;
        } else if (item === MAPS) {
            return <EnvironmentOutlined />;
        } else if (item === DOCS) {
            return <InfoCircleOutlined className='h-18' />;
        }
    }

    const handleMenuItemClick = (item: string) => {
        // if (item === POWER_GENERATION) {
        //     navigate(item);
        // } else if (item === FUEL) {
        //     navigate(item);
        // }
        //  else
        if (item === SOLAR) {
            navigate(item);
        } else if (item === REPORTS) {
            navigate(item);
        } else if (item === CAMERAS) {
            navigate(item);
        } else if (item === ALARMS) {
            navigate(item);
        } else if (item === MAPS) {
            navigate(item);
        }
        else if (item === DOCS) {
            navigate(item);
        }
        else if (item === STARLINK) {
            navigate(item);
        }
        else
            if (item === POWER_GENERATION) {
                if (generatorList && generatorList.length > 0 && !loading) {
                    // dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${generatorList[0].equipment_id}`);
                }
                else if (generatorList && generatorList.length === 0 && !loading) {
                    dispatch(setSelectedGeneratorId(null));
                    dispatch(setSelectedSourceId(''));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}`);
                }
                else {
                    navigate(item)
                }
            }
            else if (item === FUEL) {
                if (fuelGenList && fuelGenList.length > 0 && !loading) {
                    // dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}/${fuelGenList[0].equipment_id}`);
                }
                else if (fuelGenList && fuelGenList.length === 0 && !loading) {
                    dispatch(setSelectedFuelId(null));
                    dispatch(setSelectedFuelSourceId(''));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}`);
                }
                else {
                    navigate(item)
                }
            }
    }

    useEffect(() => {
        if (generatorList && generatorList.length > 0 && !loading) {
            !selectedGeneratorId && dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
            !selectedGeneratorId && dispatch(setSelectedSourceId(generatorList[0].source_id));
        }
    }, [generatorList, loading]);

    useEffect(() => {
        if (fuelGenList && fuelGenList.length > 0 && !loading) {
            !selectedFuelId && dispatch(setSelectedFuelId(fuelGenList[0].equipment_id));
            !selectedFuelId && dispatch(setSelectedFuelSourceId(fuelGenList[0].source_id));
        }
    }, [fuelGenList, loading]);

    const VersionItem = () => (
        <>
            <div>
                Ver 2.0 API 2.45
            </div>
        </>
    );

    function capitalizeFirstLetter(str: string) {
        if (!str) return str;  // Check for empty string or null/undefined
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const siderContent = (
        <>
            {!isMobile && <div className='imageDiv'>
                <img
                    src={logo}
                    alt="logo"
                    className='actualImage'
                />
            </div>}
            <div className="selectDiv">
                <Select
                    value={selectedCustomerName}
                    loading={permittedCustomersLoading || customerSitesLoading}
                    showSearch
                    options={permittedCustomers.map(item => ({ label: item.name, value: item.customer }))}
                    suffixIcon={<img src={UserLogo} className='h-18' />}
                    onChange={handleSelectCustomerChange}
                    disabled={isDisabled}
                    className={isDisabled ? 'insideSelect select-disabled' : 'insideSelect'}
                />
            </div>
            <div className="selectDiv">
                <Select
                    disabled={customerSitesLoading || permittedCustomersLoading}
                    value={selectedSite}
                    showSearch
                    options={customerSites.map(item => ({ label: item.name, value: item.name }))}
                    className='insideSelect'
                    suffixIcon={<EnvironmentOutlined className='h-18 clr-blk' />}
                    onChange={handleSelectSiteChange}
                />
            </div>
            <Skeleton active={true} title={false} className='margin-5' loading={customerSitesLoading} paragraph={{ rows: 5 }} >
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[selectedCustomerName, selectedSite, capitalizeFirstLetter(lastSegment)]}
                    selectedKeys={[selectedCustomerName, selectedSite, chargerIdFromURL, capitalizeFirstLetter(lastSegment), selectedKey]}
                    items={menuItems}
                />
            </Skeleton>
            <div className='versionDiv'>
                <VersionItem />
            </div>
        </>
    );

    const pathSegments = location.pathname.split('/').filter(Boolean);

    const handleIntroductionClick = () => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${INTRODUCTION}`);
    }

    const signOut = () => {

        dispatch(logoutUser()).then(() => {
            // Redirect to login page or initiate login
            initiateLogin();
        });
    };

    const widgetMenu = (
        <Menu theme="light" mode="inline">
            {/* Avatar and Username Row */}
            <div className='centered16'>
                <Avatar size="large" className='avatar' icon={<UserOutlined />} />
                <div className='truncate'>
                    <div className='text-base font-bold'>
                        {userInfo?.first_name}
                    </div>
                </div>
            </div>
            {/* View Profile Item */}
            <Menu.Item key="profile" icon={<SolutionOutlined />}>
                View Profile
            </Menu.Item>
            <Menu.Item key='Introduction' onClick={handleIntroductionClick} icon={<img src={IntroLogo} alt="introduction" className='h-13' />}>
                Introduction
            </Menu.Item>
            <Menu.Item key="logout" icon={<PoweroffOutlined />} onClick={signOut}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    // if (location.pathname.match(/^\/[^/]+\/[^/]+\/?$/)) {
    //     return <Navigate to={GENERAL} replace />;
    // }

    return (
        <div className='dashboard'>
            <Layout>
                {isMobile ? (
                    <>
                        <Drawer
                            title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        type="text"
                                        icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                                        onClick={() => handleSiderClose()}
                                        className='mr-10 text-base'
                                    />
                                    <div>Menu</div>
                                </div>
                                <div className='imageDiv'>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        className='actualImage'
                                    />
                                </div>
                            </div>}
                            placement="left"
                            closable={false}
                            onClose={() => setCollapsed(!collapsed)}
                            visible={collapsed}
                        >
                            {siderContent}
                        </Drawer>
                    </>
                ) : (
                    <Sider trigger={null} collapsible collapsed={collapsed} theme="light" className='sider'>
                        {siderContent}
                    </Sider>

                )}



                <Layout>
                    <Header className='header'
                        style={{
                            background: colorBgContainer
                        }}
                    >

                        <div className='collapseDiv'>
                            <Button
                                type="text"
                                icon={collapsed ? isMobile ? <MenuFoldOutlined /> : <MenuUnfoldOutlined /> : isMobile ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => handleSiderClose()}
                                className='mr-10 text-base'
                            />
                            <div>
                                <ArrowLeftOutlined
                                    onClick={() => navigate(-1)}
                                />
                            </div>
                            <div>
                                <ReloadOutlined onClick={() => navigate(0)} />
                            </div>
                            <div className='noShow p-10'>
                                <Breadcrumb
                                    items={
                                        (pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === CHARGERS?.toLocaleLowerCase() ||
                                            pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === POWER_GENERATION?.toLocaleLowerCase() ||
                                            pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === FUEL?.toLocaleLowerCase())
                                            ? [
                                                { title: customer ? customer : selectedCustomerName },
                                                { title: site ? site : selectedSite },
                                                {
                                                    title: pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === CHARGERS?.toLocaleLowerCase()
                                                        ? CHARGERS?.toLocaleLowerCase()
                                                        : pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === POWER_GENERATION?.toLocaleLowerCase()
                                                            ? POWER_GENERATION?.toLocaleLowerCase()
                                                            : FUEL?.toLocaleLowerCase(),
                                                },
                                                {
                                                    title: pathSegments[pathSegments.length - 1] ? pathSegments[pathSegments.length - 1]?.toLocaleLowerCase() : ''
                                                },
                                            ]
                                            : [
                                                { title: customer ? customer : selectedCustomerName },
                                                { title: site ? site : selectedSite },
                                                {
                                                    title: pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === CHARGERS?.toLocaleLowerCase()
                                                        ? CHARGERS?.toLocaleLowerCase()
                                                        : pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === POWER_GENERATION?.toLocaleLowerCase()
                                                            ? POWER_GENERATION?.toLocaleLowerCase()
                                                            : pathSegments[pathSegments.length - 2]?.toLocaleLowerCase() === FUEL?.toLocaleLowerCase()
                                                                ? FUEL?.toLocaleLowerCase()
                                                                : pathSegments[pathSegments.length - 1]?.toLocaleLowerCase()
                                                },
                                            ]
                                    }
                                />
                            </div>
                        </div>
                        <div className='collapseDiv'>
                            <Button
                                type="text"
                                icon={<BellOutlined className='text-base' />}
                                className='mr-10 text-base'
                            >
                                <span className={collapsed ? '' : 'noShow'}>Notification</span>
                            </Button>
                            <div className='pointer'>
                                <Dropdown overlay={widgetMenu}>
                                    <div>
                                        <Avatar
                                            size={'small'}
                                            icon={<UserOutlined />}
                                            className='avatarAdditional'
                                        >
                                            {userInfo?.first_name && userInfo.first_name.charAt(0)}
                                        </Avatar>
                                        <span className={collapsed ? 'mr-10' : 'mr-10 noShow'}>{userInfo?.first_name}</span>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </Header>
                    <Content
                        className="content"
                    >
                        {customerSitesLoading && !permittedCustomersLoading ? <Spin size="large">
                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="loadingCol">
                                    <Typography.Title level={2} type="secondary">Loading. Fetching your site details...!</Typography.Title>
                                </Col>
                            </Row>
                        </Spin> : <Outlet />}
                    </Content>
                    {/* <Footer style={{ textAlign: 'center' }}>footer</Footer> */}
                </Layout>

            </Layout>
        </div >
    )
}

export default Dashboard;