import React from "react";
import { useRouteError, Link } from "react-router-dom";

function ErrorPage() {
    // Use useRouteError hook to get error details
    const error: any = useRouteError();

    return (
        <div style={{ textAlign: "center", padding: "50px" }}>
            <h1>Oops! Something went wrong.</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
                <br /><br />
                <i>Possible reason may include invalid route/url</i>
            </p>
            <a href="/" rel="noopener noreferrer" target="_self" style={{ color: "blue", textDecoration: "underline" }}>
                Go back to Home
            </a>
        </div>
    );
}

export default ErrorPage;
