import React from 'react';
import { Card, Col, Row, Tag, Typography, theme, Anchor } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { RootState } from '../../rootreducer';
import { fetchUserInfo, updateShowIntroStatus } from '../../redux/services/customerService';
import { AppDispatch } from '../../store';

export default function Introduction() {
    const dispatch = useDispatch<AppDispatch>();
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const responsiveCol = { xxl: 8, xl: 8, lg: 8, md: 24, sm: 24, xs: 24 };
    const responsiveCol16 = { xxl: 16, xl: 16, lg: 16, md: 24, sm: 24, xs: 24 };
    const responsiveCol10 = { xxl: 10, xl: 10, lg: 10, md: 24, sm: 24, xs: 24 };
    const responsiveCol14 = { xxl: 14, xl: 14, lg: 14, md: 24, sm: 24, xs: 24 };

    const { userInfo } = useSelector((state: RootState) => state.userInfo);

    const onChangeCheckbox = (e: any) => {
        dispatch(updateShowIntroStatus({ show_intro: false })).then((response: any) => {
            if (response?.payload?.show_intro === false) {
                dispatch(fetchUserInfo())
            }
        })
    };

    return (
        <Card className='h-full'>
            <Row style={{ backgroundColor: colorBgContainer }}>
                <Col span={24} className="p-10">
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Typography.Title level={3} type="secondary"> Welcome to e-Boost</Typography.Title>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            {userInfo?.show_intro === true && <Checkbox onChange={onChangeCheckbox} style={{ float: "right" }} className='checkBoxStyle'><Typography.Paragraph>Don't view again</Typography.Paragraph></Checkbox>}
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col {...responsiveCol} className="p-10">
                            <Card
                                title="Monitor your system"
                                styles={{ body: { padding: "5px 10px 0px 25px" } }}
                                className='cardUnselected heightMonitorYourSystem'>
                                <Typography.Paragraph >
                                    <p>
                                        Using this web portal you can fully monitor all aspects of your e-Boost systems.
                                    </p>
                                    <b>This includes:</b>
                                    <ul>
                                        <li>Location</li>
                                        <li>Power generation</li>
                                        <li>EV chargers</li>
                                        <li>Fuel Levels</li>
                                        <li>Security Cameras</li>
                                        <li>Faults</li>
                                        <li>Service History</li>
                                    </ul>
                                    If you want to learn more about your e-Boost unit, review our instructional online wiki for the latest information about your unit. <br /><br /><i>Coming Soon!</i>
                                </Typography.Paragraph>
                            </Card>
                        </Col>
                        <Col {...responsiveCol16}>
                            <Row justify="space-between">
                                <Col span={24} className="p-10">
                                    <Card title="Support options" className='cardUnselected'
                                        styles={{ body: { padding: "5px 10px 0px 25px" } }}
                                    >
                                        <Typography.Paragraph>
                                            Your e-Boost unit is supported by our <b>24/7 help desk</b>.<br />
                                            <pre>
                                                Email:&emsp;<a href="mailto:support@pioneer-emobility.com">support@pioneer-emobility.com</a><br />
                                                Phone:&emsp;<a href="tel:888-838-4043">888-838-4043</a>
                                            </pre>
                                        </Typography.Paragraph>
                                    </Card>
                                </Col>
                                <Col span={24} className="p-10">
                                    <Card title="Want to create a trouble ticket?" className='cardUnselected'
                                        styles={{ body: { padding: "5px 10px 0px 25px" } }}>
                                        <Typography.Paragraph>
                                            Have a detailed question or problem?  Create a trouble ticket <br />
                                            <pre><a target="_blank" href='http://support.pioneer-embility.com/'>http://support.pioneer-embility.com/</a></pre>
                                        </Typography.Paragraph>
                                    </Card>
                                </Col>

                                <Col span={24} className="p-10">
                                    <Card title="Other Concerns?" className='cardUnselected heightOtherConcerns'
                                        styles={{ body: { padding: "5px 10px 0px 25px" } }}>
                                        <Typography.Paragraph>
                                            If you need non-technical assistance, please contact: <br /><br />
                                            <ul>
                                                <b>Geo Murickan</b> - CEO and President<br /><a href="mailto:geo@pioneer-emobility.com">geo@pioneer-emobility.com</a><br /><a href="tel:909-532-1968">909-532-1968</a> <br /><br />
                                                {/* <b>Scott Bradley</b> - Director of Sales and Business Development<br /> <a href="mailto:scott@pioneer-emobility.com">scott@pioneer-emobility.com</a><br /><a href="tel:617-337-3537">617-337-3537</a> <br /><br /> */}
                                                <b>Brandon Martinson</b> - Service Manager  <br /><a href="mailto:bmartinson@pioneercriticalpower.com">bmartinson@pioneercriticalpower.com</a><br /><a href="tel:952-314-7296">952-314-7296</a>  <br /><br />

                                            </ul>
                                        </Typography.Paragraph>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}
