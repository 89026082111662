import { Alert, Empty } from "antd"

const NoSitesFound = () => {
    return <div>
        <Alert
            message={"Site not Found!"}
            description={"No Site Attached to this Customer. Please contact your Administrator!"}
            type={"warning"}
            style={{ width: '100%', fontSize: '20px' }}
        />
    </div>
};

export default NoSitesFound;