import { Card, Col, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { MAPS } from '../../utils/constants';
import Maps from '../maps/Maps';
import SystemStatus from './SystemStatus';
import './generalcss.css';
import GeneralTable from './GeneralDetailsTable';
import { fetchGeneralDetails } from '../../redux/services/customerService';
import NoSitesFound from '../noSitesFound/NoSitesFound';

function GeneralPageSingle() {
    const dispatch = useDispatch<AppDispatch>();

    const responsiveColForLiveStatus = {
        xxl: 9,
        xl: 9,
        lg: 9,
        md: 24,
        sm: 24,
        xs: 24,
    };

    const responsiveColForGeneralDetails = {
        xxl: 24,
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24,
    };

    const { selectedCustomerName, customerSites, selectedSite, generalDetails, generalDetailsLoading } = useSelector((state: RootState) => state.userInfo);
    const { generatorList, generatorListError, loading: isLoadingGeneratorList, selectedGeneratorId, isLoadingBPLiveData } = useSelector((state: RootState) => state.power);
    const navigate = useNavigate();

    const siteLocation = customerSites.find(site => site.name === selectedSite)?.location
    const [selectedSiteLocationUpdated, setSelectedSiteLocationUpdated] = useState<string>("");

    useEffect(() => {
        if (siteLocation && siteLocation?.latitude > 0) {
            setSelectedSiteLocationUpdated(siteLocation?.last_update_at);
        }
    }, [siteLocation]);

    const handleMapClick = () => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${MAPS}`);
    };

    return (
        <div className='general'>
            {generalDetailsLoading ? (
                <Spin size="large" className="centerSpin" />
            ) : (
                customerSites.length > 0 ?
                    <>
                        <Row className="paddingBottomRow" justify="space-between">
                            <Col span={24} className="coverObjectFit">
                                <Card size="small" className="marginTopCard">
                                    <Row gutter={[16, 16]} justify="space-between" className="rowHeight">
                                        <Col {...{ xxl: 6, xl: 6, lg: 6, md: 24, sm: 24, xs: 24 }}>
                                            <SystemStatus />
                                        </Col>
                                        <Col {...responsiveColForLiveStatus} className="fullWidthCol">
                                            <Card
                                                title={
                                                    <Row justify="space-between" align="middle">
                                                        <Col>
                                                            <span>Location</span>
                                                        </Col>
                                                        {selectedSiteLocationUpdated ? (
                                                            <Col>
                                                                <span style={{ color: '#888' }}>
                                                                    Last updated: {selectedSiteLocationUpdated + ' UTC'}
                                                                </span>
                                                            </Col>
                                                        ) : (
                                                            <Col>
                                                                <span style={{ color: '#888' }}>GPS OFF</span>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                }
                                                hoverable
                                                size="small"
                                                className="cardStyle"
                                                onClick={handleMapClick}
                                            >
                                                <Maps cardHeight="280px" enableControls={false} main={false} />
                                            </Card>
                                        </Col>
                                        <Col {...responsiveColForLiveStatus} className="fullWidthCol">
                                            {Object.keys(generalDetails).length !== 0 ? (
                                                <Card title="Your e-Boost" size="small" hoverable className="cardStyle">
                                                    <img src={generalDetails?.Truck_ImageUrl} className="imageStyle" alt="Truck" />
                                                </Card>
                                            ) : (
                                                <Card hoverable className="cardStyle">
                                                    <Typography.Paragraph className="centerText">
                                                        Image and site data not found for this site.
                                                    </Typography.Paragraph>
                                                </Card>
                                            )}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row justify="start" className="rowGraph">
                            <Col {...responsiveColForGeneralDetails}>
                                <Row justify="start" className="rowGraph">
                                    <Col span={24} className='maxHeight'>
                                        {Object.keys(generalDetails).length !== 0 && <GeneralTable />}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </> : <NoSitesFound />
            )}
        </div>
    );
}

export default GeneralPageSingle;
