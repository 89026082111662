import { Card, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export default function Docs() {
    const { selectedCustomerName, customerSites,
        selectedSite } = useSelector((state: RootState) => state.userInfo);
    const confluenceLink = customerSites.find((site) => site.name === selectedSite)?.preferences?.confluence_link;


    const [iframeHeight, setIframeHeight] = useState(window.innerHeight);

    // Update iframe height on window resize
    useEffect(() => {
        const handleResize = () => setIframeHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Card title='Documentation' id="Docs">
            {/* The script will populate content here */}
            {confluenceLink ? <iframe src={confluenceLink}
                style={{ width: "100%", height: `${iframeHeight - 190}px`, border: "none", backgroundColor: "transparent" }}
            ></iframe> : <Empty description="No documentation available" />}
        </Card>
    );
}
