import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { Spin, Row, Col, Typography, Alert } from 'antd'
import './AppContent.css';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchAllCustomerSites, fetchAllPermittedCustomers, fetchGeneralDetails, fetchUserInfo } from "../../redux/services/customerService";
import { clearUserInfo, setSelectedCustomerName, setSelectedSite } from "../../redux/slices/customerSlice";
import _ from "lodash";
import Logout from "./LogOut";
import { clearPowerState } from "../../redux/slices/powerSlice";
import { clearChargerData } from "../../redux/slices/chargerSlice";
import { GENERAL, INTRODUCTION } from "../../utils/constants";


export default function AppContent() {
    const navigate = useNavigate();
    const location = useLocation();
    const [authorizationHandled, setAuthorizationHandled] = useState<boolean>(false);
    const [loginStatus, setLoginStatus] = useState<string>('');
    const [authLoading, setAuthLoading] = useState<boolean>(true);
    const dispatch = useDispatch<AppDispatch>();
    const { permittedCustomers,
        permittedCustomersLoading,
        permittedCustomersError,
        customerSites,
        selectedCustomerName,
        loadingLogout,
        selectedSite,
        userInfo,
        loading,
        customerSitesLoading,
        customerSitesError } = useSelector((state: RootState) => state.userInfo);

    const { customer, site }: any = useParams();

    useEffect(() => {
        if (site === selectedSite && selectedSite) {
            setLoginStatus('success');
        }
    }, [selectedSite, site])

    const clearCookie = () => {
        axios
            .post(`${baseUrl}microapi/logout-cleanup/`, {}, {
                withCredentials: true,
            })
            .then((response) => {
                // Handle the response as needed
                initiateLogin();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const callUserApi = () => {
        setAuthLoading(false);
        dispatch(fetchUserInfo());
        _.isEmpty(permittedCustomers) && dispatch(fetchAllPermittedCustomers());
    }

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code');
        if (localStorage.getItem('user') === 'true') {
            // navigate('/home');
            callUserApi();
        } else if (code && !authorizationHandled) {
            setAuthorizationHandled(true);
            handleAuthorizationCode(code);
        } else {
            clearCookie();
        }
    }, [location.search]);


    const protocol: any = process.env.REACT_APP_PROTOCOL;
    const url: any = process.env.REACT_APP_API_URL;
    const baseUrl = protocol + url;
    // const reactDashboardUrl = process.env.REACT_APP_URL;

    const initiateLogin = () => {
        const authUrl: any = process.env.REACT_APP_AUTH_URL;
        // Redirect the user to the OAuth2 authorization URL
        window.location.href = authUrl;
    };

    const handleAuthorizationCode = (code: string) => {
        setAuthLoading(true);
        // Create FormData object
        const formData = new FormData();

        // Append the authorization code to the FormData
        formData.append("code", code);

        // Perform the axios request
        axios
            .post(`${baseUrl}oauth/auth0/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
                }, withCredentials: true,
            })
            .then((response) => {
                // Handle the response as needed
                console.log("Response:", response.data);
                // Now that the authentication is successful, switch to sending cookies
                axios.defaults.withCredentials = true;

                localStorage.setItem('user', 'true');
                setLoginStatus('success');
                // navigate("/Home");
                navigate("/", { replace: true });
                // callUserApi();
            })
            .catch((error) => {
                console.log(error);
                console.log("Response data:", error.response.data); // Log the response data for more details
                if (error.response.status === 401) {
                    clearCookie();
                }
                setLoginStatus('error');
                if (error.response.status === 400) {
                    navigate("/errorLogin");
                }
                // initiateLogin();
            }).finally(() => {
                setAuthLoading(false);
            });
    };
    console.log("permittedCustomersError", permittedCustomersError, "loginStatus", loginStatus)

    useEffect(() => {
        if (!permittedCustomersLoading && !_.isEmpty(permittedCustomers)) {
            const permittedCustomerExists = permittedCustomers.find(data => data.customer === customer);
            if (_.isEmpty(permittedCustomerExists) && !_.isEmpty(customer)) {
                window.location.href = `/`;
            }
            else {
                const permittedSiteExists = customerSites.find(data => data.name === site);
                if (customer && site && customer && !permittedSiteExists) {
                    const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);
                    if (!_.isEmpty(selectedCustomerObj)) {
                        dispatch(fetchAllCustomerSites(customer)).then((response: any) => {
                            // Assuming the response is an array of sites
                            if (response?.payload.length === 0) {
                                dispatch(setSelectedCustomerName(customer));
                                dispatch(setSelectedSite('noSitesAttached'));
                                navigate(`/${customer}/noSitesAttached/${GENERAL}`);
                                return;
                            }
                            const siteExists = response?.payload.find((siteData: any) => siteData.name === site);

                            if (siteExists) {
                                console.log('Site found:', siteExists);
                                dispatch(setSelectedCustomerName(customer));
                                dispatch(setSelectedSite(site));
                                // Perform additional actions with siteExists if needed
                            } else {
                                dispatch(fetchAllCustomerSites(permittedCustomers[0]?.customer)).then((response: any) => {
                                    if (response?.payload.length === 0) {
                                        dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                        dispatch(setSelectedSite('noSitesAttached'));
                                        navigate(`/${permittedCustomers[0]?.customer}/noSitesAttached/${GENERAL}`);
                                        return;
                                    }
                                    const selectSiteFromResponse = response?.payload[0]?.name;
                                    dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                    dispatch(setSelectedSite(selectSiteFromResponse));
                                    userInfo?.show_intro ? navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/${INTRODUCTION}`) : navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                                })
                            }
                        });
                    }
                } else {
                    dispatch(fetchAllCustomerSites(permittedCustomers[0]?.customer)).then((response: any) => {
                        if (response?.payload.length === 0) {
                            dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                            dispatch(setSelectedSite('noSitesAttached'));
                            navigate(`/${permittedCustomers[0]?.customer}/noSitesAttached/${GENERAL}`);
                            return;
                        }
                        const selectSiteFromResponse = response?.payload[0]?.name;
                        dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                        dispatch(setSelectedSite(selectSiteFromResponse));
                        userInfo?.show_intro ? navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/${INTRODUCTION}`) : navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                    })
                }
            }
        }
    }, [permittedCustomersLoading, permittedCustomers, userInfo]);

    useEffect(() => {
        if (!_.isEmpty(customer) && !_.isEmpty(site) && customer !== selectedCustomerName && site !== selectedSite && !_.isEmpty(selectedCustomerName) && !_.isEmpty(selectedSite)) {
            console.log("entered");
            if (customer !== selectedCustomerName) {
                dispatch(clearUserInfo());
                dispatch(clearPowerState());
                dispatch(clearChargerData());
                const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);
                if (selectedCustomerObj) {
                    dispatch(setSelectedCustomerName(customer));
                    dispatch(fetchAllCustomerSites(customer)).then((response: any) => {
                        if (response?.payload.length === 0) {
                            dispatch(setSelectedCustomerName(customer));
                            dispatch(setSelectedSite('noSitesAttached'));
                            navigate(`/${customer}/noSitesAttached/${GENERAL}`);
                            return;
                        }
                        // Assuming the response is an array of sites
                        const siteExists = response?.payload.find((siteData: any) => siteData.name === site);

                        if (siteExists) {
                            console.log('Site found:', siteExists);
                            dispatch(setSelectedCustomerName(customer));
                            dispatch(setSelectedSite(site));
                            // Perform additional actions with siteExists if needed
                        } else {
                            dispatch(fetchAllCustomerSites(permittedCustomers[0]?.customer)).then((response: any) => {
                                if (response?.payload.length === 0) {
                                    dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                    dispatch(setSelectedSite('noSitesAttached'));
                                    navigate(`/${permittedCustomers[0]?.customer}/noSitesAttached/${GENERAL}`);
                                    return;
                                }
                                const selectSiteFromResponse = response?.payload[0]?.name;
                                dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                dispatch(setSelectedSite(selectSiteFromResponse));
                                userInfo?.show_intro ? navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/${INTRODUCTION}`) : navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                            })
                        }
                    })
                }
            }
            else if (site !== selectedSite) {
                const siteExists = customerSites.find((siteData: any) => siteData.name === site);
                if (siteExists) {
                    dispatch(setSelectedSite(site));
                }
            }
        }
    }, [customer, site, userInfo])

    useEffect(() => {
        dispatch(fetchGeneralDetails({ selectedCustomerName, avail_sites: customerSites, selectedSite }))
    }, [selectedSite])


    return (
        <div className="AppContent custom-scrollbar">
            {
                <div>
                    {!loadingLogout && (loading || authLoading || (location.pathname === '/' && permittedCustomersError?.length === 0)) && (
                        <Spin size="large" style={{ backgroundColor: 'transparent' }}>
                            <Row justify="center">
                                <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                                    <Typography.Title level={2} type="secondary">
                                        Loading. Please wait!
                                    </Typography.Title>
                                </Col>
                            </Row>
                        </Spin>
                    )}
                    {
                        !loadingLogout && loginStatus !== 'error' && permittedCustomersError &&
                        <div className="centerContainer">
                            <Alert
                                message={["Network Error", "Request failed with status code 500"].includes(permittedCustomersError) ? permittedCustomersError : "Contact Admin"}
                                description={["Network Error", "Request failed with status code 500"].includes(permittedCustomersError) ? "Unable to reach the server" : "No permission has been added to your account. Please contact e-Boost admin."}
                                type={["Network Error", "Request failed with status code 500"].includes(permittedCustomersError) ? "error" : "warning"}
                                showIcon
                                style={{ width: '100%', fontSize: '20px' }}
                                action={<Logout />}
                            />
                        </div>
                    }
                    {
                        !loadingLogout && authLoading === false && loginStatus !== 'error' && !permittedCustomersLoading && permittedCustomers.length === 0 && permittedCustomersError?.length === 0 &&
                        <div className="centerContainer">
                            <Alert
                                message={"No customers found!"}
                                description={"Please contact your administrator."}
                                type={"error"}
                                showIcon
                                style={{ width: '100%', fontSize: '20px' }}
                                action={<Logout />}
                            />
                        </div>
                    }
                    {
                        ((loadingLogout === false && loginStatus === 'success') || customerSitesLoading) && <Outlet />
                    }
                    {loadingLogout === true && <Spin size="large">
                        <Row justify="center">
                            <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                                <Typography.Title level={2} type="secondary">Logging you Out. Please wait!</Typography.Title>
                            </Col>
                        </Row>
                    </Spin>}
                </div>
            }
        </div>
    );
}
