import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { CancelTokenSource } from "axios";

const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl: any = protocol + url;

export interface Picture {
    id: number;
    createdAt: string; // Consider using Date if you plan to convert this later
    cameraName: string;
    truckId: string;
    pictureType: string;
    picturePath: string;
    pictureSize: number;
    detectedAs: string;
    videoExists: boolean;
    videoUrl: string;
}

let cancelTokenSource8: CancelTokenSource | null = null;

// Example of how to use the interface in a function or a Redux action
export const fetchPictureListByCamera = createAsyncThunk<Picture[], { selectedCustomerName: string; selectedSite: string }>(
    'camera/cameraInfoList',
    async (payload, thunkAPI) => {
        const { selectedCustomerName, selectedSite } = payload;
        if (cancelTokenSource8) {
            cancelTokenSource8.cancel('Request canceled');
        }

        cancelTokenSource8 = axios.CancelToken.source();
        try {
            const { data } = await axios.get<Picture[]>(`${baseUrl}camera/pictures/listForDisplay/${selectedCustomerName}/${selectedSite}/`, {
                withCredentials: true,
                headers: {
                    'customer': selectedCustomerName,
                },
                cancelToken: cancelTokenSource8.token,
            });
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);